import { Backdrop, Button, CircularProgress, Container, Grid, TextareaAutosize, Typography } from "@mui/material";
import axios from "axios";
import { useRef, useState } from "react";
import defaultCateogries from '../categories/categories';

export default function Explore() {
    const [open, setOpen] = useState(false);
    const [categories, setCategories] = useState<{ key: string, doc_count: number }[][]>([]);

    const txt = useRef<HTMLTextAreaElement>(null);

    const handleClicked = () => {
        setOpen(true)
        setCategories([]);
        axios({
            url: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api/classification' : '/api/classification',
            method: 'POST',
            data: {
                text: txt.current!.value,
            }
        }).then((res) => {
            setOpen(false);
            setCategories(res.data.result);
        }).catch((err) => {
            setOpen(false);
        })
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && e.ctrlKey) {
            handleClicked();
            e.preventDefault();
        }
    }

    return (
        <div className="w-full py-5 flex items-center" style={{ backgroundColor: '#BFEAF9', minHeight: '320px' }} >
            <Container maxWidth='md'>
                <div className="flex mb-5">
                    <TextareaAutosize onKeyDown={handleKeyDown} ref={txt} maxRows={3} className="grow px-8 rounded-l-xl outline-none" style={{ backgroundColor: '#EAF8FD', lineHeight: '1.5', padding: '16px' }} placeholder="Type Your Text Here..." />
                    <Button onClick={handleClicked} color='secondary' variant="contained" className='rounded-r-xl rounded-l-none text-white' >Go</Button>
                </div>
                {
                    categories.length > 0 &&
                    <div className="border-l-2 pl-8" style={{ borderColor: '#FD7459' }}>
                        <Typography className="text-xl mb-2 pl-2">we find 3 result. which one is closer?</Typography>
                        {
                            categories.map(categories1 => <Grid container >
                                {
                                    categories1.map((category) => <Grid item md={4} xs={12} key={category.key} className="p-2" >
                                        <div className="rounded-lg p-4 flex flex-row space-x-2 items-center h-full" style={{ backgroundColor: '#EAF8FD' }}>
                                            {
                                                defaultCateogries[category.key] ? <>
                                                    <img className="w-8" src={defaultCateogries[category.key].icon} alt='' />
                                                    <Typography>{defaultCateogries[category.key].key}</Typography>
                                                    <Typography>{category.doc_count.toFixed(4)}</Typography>
                                                </>
                                                    :
                                                    <>
                                                        <Typography>{category.key}</Typography>
                                                        <Typography>{category.doc_count.toFixed(4)}</Typography>
                                                    </>
                                            }
    
                                        </div>
                                    </Grid>)
                                }
                                {
                                    // @ts-ignore
                                    Array.apply(null, Array(3 - categories1.length)).map((category, index) => <Grid item md={4} xs={12} key={index} className="p-2" >
                                    <div className="rounded-lg p-4 flex flex-row space-x-2 items-center h-full" style={{ backgroundColor: '#EAF8FD' }}>
                                        {
                                                <>
                                                    <img className="w-8" alt='' />
                                                    <Typography>Not found</Typography>
                                                    <Typography>0</Typography>
                                                </>
                                        }

                                    </div>
                                </Grid>)
                                }
                            </Grid>)
                        }
                    </div>
                }
            </Container>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
