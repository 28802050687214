import { Typography } from "@mui/material";

export default function HomeTitle() {
    return (
        <div className="grow">
            <div className="border-l-2 pl-8" style={{ borderColor: '#30BCED' }}>
                <Typography className="font-bold text-3xl">What is</Typography>
                <Typography className="font-bold text-4xl">Text classification ?</Typography>
            </div>
            <Typography className="mt-4 text-2xl">Text classification is an NLP task that involves categorizing text into predefined classes. It uses machine learning models to automatically assign labels based on the content of the text. This process helps organize and analyze large amounts of textual data efficiently.</Typography>
        </div>
    )
}
