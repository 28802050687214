import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app/App';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Home from './components/home/Home';
import Support from './components/support/Support';


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/support/:id",
                element: <Support/>
            }
        ]
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
            <RouterProvider router={router} />
    </React.StrictMode>
);


