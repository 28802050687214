import { Button, Container, Grid } from "@mui/material";
import defaultCategories from "../categories/categories";
import Category from "./Category";
import { useEffect, useState } from "react";
import axios from "axios";
// @ts-ignore
import what from './what.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function Categories() {
    const [categories, setCategories] = useState(defaultCategories)
    const [open, setOpen] = useState(false);

    useEffect(() => {
        axios({
            url: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api/dataset' : '/api/dataset',
        }).then((res) => {
            const cats = {...categories}
            for (const cat of res.data.result) {
                if (cats[cat.key]) {
                    cats[cat.key].count = cat.doc_count;
                }
            }
            setCategories(cats);
        }).catch((err) => {

            // TODO show error message
        })
    }, []);
    let catIds = Object.keys(categories)
    if (!open) {
        catIds = catIds.slice(0, 8)
    }

    const handleOpenMore = () => {
        setOpen(!open);
    }


    return (
        <Container className="my-12 flex flex-col space-y-0">
            <img className="w-96" src={what} alt=""/>
            <Grid container  spacing={{ xs: 1, sm: 5, md: 10 }}>
                {
                    catIds.map(id => <Grid key={id} item md={3} sm={4} xs={6}>
                        <Category category={categories[id]} />
                    </Grid>)
                }
            </Grid>
            <div className="text-center pt-4">
                <Button onClick={handleOpenMore} className="text-black text-2xl" endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>See More Category</Button>
            </div>
        </Container>
    )
}
