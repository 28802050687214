import { ThemeProvider, createTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function App() {
    const theme = createTheme({
        typography: {
            button: {
                textTransform: 'none'
            }
        },
        palette: {
            primary: {
                main: '#BFEAF9',
            },
            secondary: {
                main: '#FD7459'
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <Outlet />
        </ThemeProvider>
    )
}
