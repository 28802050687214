export default {
    "3": {
        key: "Other",
        icon: "/img/box.png"
    },
    "6": {
        key: "Pregnancy",
        icon: "/img/pregnancy.png"
    },
    "7": {
        key: "Family",
        icon: "/img/family.png"
    },
    "8": {
        key: "Maturity",
        icon: "/img/maturity.png"
    },
    "9": {
        key: "Sport",
        icon: "/img/sport.png"
    },
    "11": {
        key: "Relationship",
        icon: "/img/relationship.png"
    },
    "12": {
        key: "Period",
        icon: "/img/period.png"
    },
    "13": {
        key: "Cosmetics",
        icon: "/img/cosmatic.png"
    },
    "14": {
        key: "Motivation",
        icon: "/img/motivation.png"
    },
    "16": {
        key: "Animation",
        icon: "/img/animation.png"
    },
    "17": {
        key: "Course",
        icon: "/img/course.png"
    },
    "18": {
        key: "Movies & Series",
        icon: "/img/movie.png"
    },
    "19": {
        key: "K pop",
        icon: "/img/k pop.png"
    },
    "20": {
        key: "Fashion",
        icon: "/img/fashion.png"
    },
    "21": {
        key: "Celebrity",
        icon: "/img/celebrity.png"
    },
    "22": {
        key: "Religious",
        icon: "/img/religion.png"
    },
    "25": {
        key: "Poll",
        icon: "/img/poll.png"
    },
    "28": {
        key: "Music",
        icon: "/img/music.png"
    },
    "29": {
        key: "Women's rights",
        icon: "/img/womens right.png"
    },
    "31": {
        key: "Fun & Cool",
        icon: "/img/fun and cool.png"
    },
    "33": {
        key: "Medical",
        icon: "/img/medical.png"
    },
    "34": {
        key: "Book",
        icon: "/img/bookshelf.png"
    },
    "35": {
        key: "Cooking",
        icon: "/img/cooking.png"
    },
    "36": {
        key: "Art",
        icon: "/img/art.png"
    },
    "37": {
        key: "Anime",
        icon: "/img/anime.png"
    },
    "38": {
        key: "English",
        icon: "/img/english.png"
    },
    "39": {
        key: "Nature rights",
        icon: "/img/piroz.png"
    },
    "40": {
        key: "Tecnology",
        icon: "/img/tec.png"
    },
    "41": {
        key: "Breaking taboo",
        icon: "/img/tec1.png"
    },
    "42": {
        key: "Breaking taboo",
        icon: "/img/tec1.png"
    },
    "43": {
        key: "Tourism",
        icon: "/img/tec1.png"
    },
    "44": {
        key: "historical",
        icon: "/img/tec1.png"
    },
    "45": {
        key: "political",
        icon: "/img/tec1.png"
    },
    "46": {
        key: "Kidrama",
        icon: "/img/tec1.png"
    },
    "47": {
        key: "Personal growth",
        icon: "/img/tec1.png"
    },
    "48": {
        key: "Job and income",
        icon: "/img/tec1.png"
    },
    "49": {
        key: "Digital currency",
        icon: "/img/tec1.png"
    },
    "50": {
        key: "free talk",
        icon: "/img/tec1.png"
    },
    "51": {
        key: "scary",
        icon: "/img/tec1.png"
    }
} as { [key: string]: { key: string; icon: string, count?:number } };

export interface ICategory {
    key: string;
    icon: string;
    count?: number;
} 