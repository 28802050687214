import { Skeleton } from '@mui/material'
//@ts-ignore
import textImage from './textImage.png'
//@ts-ignore
import tick from './tick.png'


export default function Example() {
    return (
        <div className="grow rounded-lg p-8 h-fit" style={{ border: '1px solid #EAEAEB', filter: 'drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.15))' }}>
            <div className="rounded-lg py-2.5 px-8 flex flex-row space-x-4 mb-7" style={{ border: '0.5px solid #747478' }}>
                <img className='w-8' src={textImage} alt=''/>
                <div className='flex flex-col grow justify-center space-y-1'>
                    <Skeleton width={'40%'} height={6} />
                    <Skeleton width={'100%'} height={6} />
                    <Skeleton width={'100%'} height={6} />
                </div>
            </div>
            <div className='flex flex-row space-x-4'>
                <div className="rounded-lg p-4 grow" style={{ border: '0.5px solid #747478' }}>
                    News
                </div>
                <div className="rounded-lg p-4 grow relative" style={{ border: '2px solid #59C9F1' }}>
                    Health
                    <img className='absolute -top-3 -right-3 w-8' src={tick} alt=''/>
                </div>
                <div className="rounded-lg p-4 grow" style={{ border: '0.5px solid #747478' }}>
                    Sport
                </div>
            </div>
        </div>
    )
}
