import { Container, Hidden, Typography } from '@mui/material';
// @ts-ignore
import icon from './icon.png';
import SupportButton from '../support/SupportButton';

export default function Header() {
    return (
        <div style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)' }}>
            <Container className='h-28 flex flex-row space-x-2 items-center' >
                <img className='w-12' src={icon} alt='ZiraK' />
                <Typography className='grow text-2xl'>ZiraK</Typography>
                <Hidden smDown>
                    <SupportButton />
                </Hidden>
            </Container>
        </div>
    )
}
