import { Container, Typography } from "@mui/material";
import SupportButton from "../support/SupportButton";

export default function Footer() {
  return (
    <Container className="flex items-center space-x-2 py-6">
        <Typography className="text-3xl grow">For more information and price call us</Typography>
        <SupportButton />
    </Container>
  )
}
