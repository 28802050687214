import Header from "../header/Header";
import HomeTitle from "./HomeTitle";
import Example from "./Example";
import { Container, Hidden } from "@mui/material";
import Explore from "./Explore";
import Footer from "./Footer";
import Categories from "./Categories";

export default function Home() {
    return (
        <>
            <Header />
            <Container className="flex flex-col">
                <div className="flex flex-row space-x-10 py-12">
                    <HomeTitle />
                    <Hidden mdDown>
                        <Example />
                    </Hidden>
                </div>
            </Container>
            <Explore />
            <Categories />
            <Footer />
        </>
    )
}
