import { Skeleton, Typography } from "@mui/material";
import { ICategory } from "../categories/categories";

interface IProps {
    category: ICategory
}


export default function Category({ category }: IProps) {
    return (
        <div className="rounded-2xl border flex flex-col space-y-2 p-4 items-center" style={{ borderColor: '#EAEAEB' }}>
            <img className="w-16" src={category?.icon} alt={category?.key} />
            <Typography className="text-lg">{category?.key}</Typography>
            <Typography className="border rounded-xl py-2 w-full text-center text-lg" style={{ borderColor: '#BFEAF9', color: '#2CABD8' }}>
                {
                    category?.count ? 
                    convertToK(category.count)
                    :
                    <Skeleton className="m-auto" width={'50%'}/>
                }
            </Typography>
        </div>
    )
}

function convertToK(number: number) {
    if (number < 1000) {
        return number.toString();
    } else {
        var thousands = (number / 1000).toFixed(1);
        return thousands + "K";
    }
}